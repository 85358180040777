<template>
  <v-container fluid>
    <env-chip />
    <v-row>
      <v-col cols="12">
        <v-form ref="form" class="mt-3">
          <v-text-field
            v-model="user.mail"
            outlined
            type="email"
            :label="$t('formUser.email')"
            :rules="[$rules.email]"
            validate-on-blur
          />
          <v-text-field
            v-model="user.password"
            outlined
            type="password"
            :label="$t('formUser.password')"
            :rules="[$rules.required]"
            validate-on-blur
            @keyup.enter="login"
          />
        </v-form>
      </v-col>
    </v-row>
    <v-row justify="center" class="px-3">
      <v-btn color="secondary" rounded large block @click="login">
        Entrar
      </v-btn>
      <v-btn text class="mt-3" block color="secondary" @click="register">
        {{ $t("pages.login.register") }}
      </v-btn>
      <v-btn color="secondary" text block @click="recoverPassword">
        {{ $t("pages.login.forgotPassword") }}
      </v-btn>
    </v-row>
    <v-row class="terms-login">
      <a
        href="https://s3.amazonaws.com/files.prod.appbio/APPBIO_Termos_e_Condi%C3%A7%C3%B5es_Gerais_de_Uso_e_ou_de_VendaV2.pdf"
        target="_blank"
        >Termos de Uso</a
      >
      <span>|</span>

      <a
        href="https://s3.amazonaws.com/files.prod.appbio/APPBIO_Pol%C3%ADtica_de_PrivacidadeV2.pdf"
        target="_blank"
        >Política de Privacidade</a
      >
    </v-row>

    <dialog-forgot-password ref="dialogForgotPassword" />
  </v-container>
</template>

<script>
import { login } from "@/services/external/users-service";
export default {
  components: {
    DialogForgotPassword: () =>
      import("@/domains/auth/presentation/components/DialogForgotPassword"),
  },
  data: () => ({
    user: {},
    alertMsg: null,
  }),
  computed: {
    icone() {
      return require("@/assets/icone.png");
    },
  },
  mounted() {
    this.$store.dispatch("settings/showSheetInstallApp");
    this.$store.dispatch("login/logout");
  },
  methods: {
    async login() {
      try {
        if (!this.$refs.form.validate()) return;
        this.$store.dispatch("loading/openDialog");
        let response = await login(this.user);
        await this.$store.dispatch("login/login", response.data);
      } catch (error) {
        this.$errorHandler(error, true);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    register() {
      this.$router.push({ name: "auth.register" });
    },
    recoverPassword() {
      const email = this.user.mail || null;
      this.$refs.dialogForgotPassword.open(email);
    },
  },
};
</script>

<style scoped>
.pt-login {
  padding-top: 150px;
}
.terms-login {
  font-size: 12px;
  justify-content: center;
  padding-top: 20px;
  gap: 10px;
}
</style>
